import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { APP_SETTINGS } from '../models/app-settings';

@Injectable()
export class AppLoadService {

  constructor(private httpClient: HttpClient) { }

  getSettings(): Promise<any> {
    const promise = this.httpClient.get<any>('/assets/env/env-config.json')
      .toPromise()
      .then(settings => {
        APP_SETTINGS.ENVIROCOM_API_ENDPOINT = settings.ENVIROCOM_API_ENDPOINT;
        APP_SETTINGS.ORGANIZATION_ID = settings.ORGANIZATION_ID;
        APP_SETTINGS.PROJECT_ID = settings.PROJECT_ID;
        APP_SETTINGS.PROJECT_ID_BEACH_REPORTS = settings.PROJECT_ID_BEACH_REPORTS;
        APP_SETTINGS.PROJECT_ID_CONDITIONS_REPORT = settings.PROJECT_ID_CONDITIONS_REPORT;
        APP_SETTINGS.PROJECT_ID_EQUIPMENT_REPORT = settings.PROJECT_ID_EQUIPMENT_REPORT;
        APP_SETTINGS.PROJECT_ID_INCIDENT_REPORT = settings.PROJECT_ID_INCIDENT_REPORT;
        APP_SETTINGS.LIFEGUARD_BEACH_DATA = settings.LIFEGUARD_BEACH_DATA;
        APP_SETTINGS.LIFEGUARD_INCIDENT_DATA = settings.LIFEGUARD_INCIDENT_DATA;
        APP_SETTINGS.LIFEGUARD_CONDITIONS_DATA = settings.LIFEGUARD_CONDITIONS_DATA;
        APP_SETTINGS.LIFEGUARD_EQUIPMENT_DATA = settings.LIFEGUARD_EQUIPMENT_DATA;
        APP_SETTINGS.GOLDCOAST_SEAWAY_TIDE_GAUGE = settings.GOLDCOAST_SEAWAY_TIDE_GAUGE;
        APP_SETTINGS.GOLDCOAST_WAVE_BUOY = settings.GOLDCOAST_WAVE_BUOY;
        APP_SETTINGS.SOURCE_WAVE_BOUY = settings.SOURCE_WAVE_BOUY;
        APP_SETTINGS.SOURCE_SEAWAY_TIDE_GAUGE = settings.SOURCE_SEAWAY_TIDE_GAUGE;
        APP_SETTINGS.TIME_ZONE = settings.TIME_ZONE;
        APP_SETTINGS.TIME_ZONE_IN_HOURS = settings.TIME_ZONE_IN_HOURS;
        APP_SETTINGS.CONSTANT_TIME = settings.CONSTANT_TIME;
        APP_SETTINGS.CLIENT_LOGO = settings.CLIENT_LOGO;
        APP_SETTINGS.LIFEGUARD_CONDITION_WATER_QUALITY_DATA_FIELD = settings.LIFEGUARD_CONDITION_WATER_QUALITY_DATA_FIELD;
        APP_SETTINGS.LIFEGUARD_CONDITION_WATER_QUALITY_DATASET = settings.LIFEGUARD_CONDITION_WATER_QUALITY_DATASET;
        APP_SETTINGS.LIFEGUARD_CONDITION_WIND_DATASET = settings.LIFEGUARD_CONDITION_WIND_DATASET;
        APP_SETTINGS.LIFEGUARD_CONDITION_WATER_QUALITY_POLLUTION_FORECAST_TIME_STAMP = settings.LIFEGUARD_CONDITION_WATER_QUALITY_POLLUTION_FORECAST_TIME_STAMP;
        APP_SETTINGS.LIFEGUARD_DATETIME_FORMAT = settings.LIFEGUARD_DATETIME_FORMAT;
        return settings;
      });

    return promise;
  }
}
