import { AfterViewInit, Component, ElementRef, ViewChild, Output, EventEmitter } from "@angular/core";
declare var MediaRecorder: any;

@Component({
  selector: "app-webcam-snapshot",
  templateUrl: "./webcam-snapshot.component.html",
  styleUrls: ["./webcam-snapshot.component.scss"]
})
export class WebcamSnapshotComponent implements AfterViewInit {
  WIDTH = 1280;
  HEIGHT = 720;

  @Output() newItemEvent = new EventEmitter<string>();
  addNewItem(value: string) {
    this.newItemEvent.emit(value);
  }

  @ViewChild("video", undefined)
  public video: ElementRef;

  @ViewChild("canvas", undefined)
  public canvas: ElementRef;

  captures: string[] = [];
  error: any;
  isCaptured: boolean;
  isVideoCaptured: boolean;

  theStream: any;
  theRecorder: any;
  recordedChunks = [];

  useFrontCamera = true;
  
  async ngAfterViewInit() {
    await this.setupDevices();
    this.isVideoCaptured = false;
  }

  addEventListener() {
    this.useFrontCamera = !this.useFrontCamera;

    this.ngAfterViewInit();
  }

  async setupDevices() {

    let constraints = {
      video: {
        width: {
          min: 1280,
          ideal: 1920,
          max: 2560,
        },
        height: {
          min: 720,
          ideal: 1080,
          max: 1440,
        },
        facingMode: this.useFrontCamera ? "user" : "environment"
      },
    };

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

      // constraints.video.facingMode = this.useFrontCamera ? "user" : "environment";

      // await navigator.mediaDevices.getUserMedia(constraints); 

      // await navigator.mediaDevices.getUserMedia({
      //       video: {
      //         facingMode: 'environment',
      //         width: {
      //             min: 1280
      //         },
      //         height: {
      //             min: 720
      //         }
      //       }
      //     })
   await navigator.mediaDevices.getUserMedia(constraints)
  .then((stream) => {
    if (stream) {          
          this.startRecording(stream);
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.play();
          this.error = null;          
        } else {
          this.error = "You have no output video device";
        }
  })
  .catch((err) => {
    this.error = err;
  });

      // try {
      //   const stream = await navigator.mediaDevices.getUserMedia({
      //     video: {
      //       facingMode: 'environment',
      //       width: {
      //           min: 1280
      //       },
      //       height: {
      //           min: 720
      //       }
      //   }
      //   });
      //   if (stream) {
      //     this.startRecording(stream);
      //     this.video.nativeElement.srcObject = stream;
      //     this.video.nativeElement.play();
      //     this.error = null;          
      //   } else {
      //     this.error = "You have no output video device";
      //   }
      // } catch (e) {
      //   this.error = e;
      // }
    }
  }

  stopVideoStream(stream) {
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  async startRecording(stream) {
    var options;
    if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
      options = {mimeType: 'video/webm; codecs=vp9'};
    } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
      options = {mimeType: 'video/webm; codecs=vp8'};
    } else {
      options = {mimeType: 'video/webm;'};
    }

    this.theStream = stream;
    var video = document.querySelector('video');
    video.srcObject = stream;
    let recorder; 
    try {
      // recorder = new MediaRecorder(stream, {mimeType : "video/webm"});
      recorder = new MediaRecorder(stream, options);
    } catch (e) {
      console.error('Exception while creating MediaRecorder: ' + e);
      return;
    }
    
    this.theRecorder = recorder;
    recorder.ondataavailable = 
        (event) => { this.recordedChunks.push(event.data); };
    recorder.start(100);
    
  }

  enableStopVideo() {
    this.closeCamera();
    this.ngAfterViewInit();
    // this.stopVideoStream(this.theStream); 
    this.isVideoCaptured = true;
    // console.log(this.isVideoCaptured);
  }

  stopRecording() {
    this.isVideoCaptured = false;    
      if (typeof(this.theRecorder) != 'undefined' && this.theRecorder != null) {
        this.theRecorder.stop();
        var blob = new Blob(this.recordedChunks, {type: "video/mp4"});
        var url =  URL.createObjectURL(blob);
        this.getBase64FromUrl(url).then((value) => { this.addNewItem(value+""); });
        this.closeCamera();
        // this.video.nativeElement.srcObject.getVideoTracks()[0].stop();        
      }
      this.closeCamera();
  }

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;   
        resolve(base64data);
      }
    });
  }

  capture() {
    this.drawImageToCanvas(this.video.nativeElement);
    this.captures.push(this.canvas.nativeElement.toDataURL("image/png"));
    this.isCaptured = true;
    this.isVideoCaptured = true;
  }

  removeCurrent() {
    this.isCaptured = false;
  }

  setPhoto(idx: number) {
    this.isCaptured = true;
    this.isVideoCaptured = true;
    var image = new Image();
    image.src = this.captures[idx];
    this.drawImageToCanvas(image);

    this.addNewItem(this.captures[idx]+"");
    this.closeCamera();
  }

  drawImageToCanvas(image: any) {
    this.canvas.nativeElement
      .getContext("2d")
      .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
  }

  closeCaptureWindow() {
    this.video.nativeElement.srcObject.getVideoTracks()[0].stop();
  }

  closeCamera() {
    this.video.nativeElement.srcObject.getVideoTracks()[0].stop();
  }
  
}
