/**
 * Created By : Venkat Salagrama
 */

import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common'

// Services
import { routerTransition } from '../../services/config/config.service';
import { ApiService } from '../../services/api/api.service';

import { AppConfiguration } from 'src/app/configs/app-configuration';

@Component({
  selector: 'app-forgotpwd',
  templateUrl: './forgotpwd.component.html',
  styleUrls: ['./forgotpwd.component.css'],
  animations: [routerTransition()],
  host: { '[@routerTransition]': '' },
  providers: [DatePipe]
})


export class ForgotpwdComponent implements OnInit {

  active: string;

  firstStep: boolean = true; 
  secondStep: boolean = false; 
  thirdStep: boolean = false; 

  clientLogo: string;

  forgotForm = this.formBuilder.group({
    emailAddress: ['', [Validators.required]]
  });
  
  otpForm = this.formBuilder.group({
    firstChar: ['', [Validators.required]],
    secondChar: ['', [Validators.required]],
    thirdChar: ['', [Validators.required]],
    fourthChar: ['', [Validators.required]]
  });

  passwordForm = this.formBuilder.group({
    password: ['', [Validators.required]],
    confirmPassword: ['', [Validators.required]]
  });


  // create reportAddForm of type FormGroup
  forgotPwdForm: FormGroup; 

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private toastr: ToastrService, public datepipe: DatePipe) {
    // Detect route changes for active sidebar menu
    this.router.events.subscribe((val) => {
      this.routeChanged(val);
    });
    this.clientLogo = AppConfiguration.CLIENTLOGO;
  }

  ngOnInit() {
  }

  // Detect route changes for active sidebar menu
  routeChanged(val) {
    this.active = val.url;
  }

  stepOne() {
    this.apiService.forgotPasswordUserVerification(this.forgotForm.value.emailAddress).subscribe(res => {
      // console.log(JSON.stringify(res));
      if(res.email === this.forgotForm.value.emailAddress) {
        this.toastr.success('Success', 'OTP is sent to your registered Email address !');
        this.firstStep = false; 
        this.secondStep = true; 
        this.thirdStep = false; 
      } else {
        this.toastr.error('Failed', 'Email Address / Username is not valid. Please verify !');
        this.firstStep = true; 
        this.secondStep = false; 
        this.thirdStep = false; 
      }      
    }, (e) => {
      this.toastr.error('Failed', 'Email Address / Username is not valid. Please verify !');
      console.log(e);
    });
  }

  stepTwo() {
    var otp = this.otpForm.value.firstChar+this.otpForm.value.secondChar+this.otpForm.value.thirdChar+this.otpForm.value.fourthChar;
    this.apiService.forgotPasswordOtpValidation(this.forgotForm.value.emailAddress, otp).subscribe(res => {
      if(res.status === 'Success') {
        this.firstStep = false; 
        this.secondStep = false; 
        this.thirdStep = true; 
      } else {
        this.toastr.error('Failed', res.message);
        this.firstStep = false; 
        this.secondStep = true; 
        this.thirdStep = false; 
      }
    }, (e) => {
      this.toastr.error('Failed', 'Error while OTP Validation !');
      console.log(e);
    });
  }

  stepThree() {
    if(this.passwordForm.value.password === this.passwordForm.value.confirmPassword) {
      this.apiService.forgotPasswordUpdatePassword(this.forgotForm.value.emailAddress, this.passwordForm.value.password).subscribe(res => {
        if(res.status === 'Success') {
          /*this.firstStep = false; 
          this.secondStep = false; 
          this.thirdStep = true; */

          this.toastr.success('Success', 'Password Updated successfully !');
          this.router.navigate(['/login']);

        } else {
          this.toastr.error('Failed', res.message);
          this.firstStep = false; 
          this.secondStep = false; 
          this.thirdStep = true; 
        }
      }, (e) => {

        this.firstStep = false; 
        this.secondStep = false; 
        this.thirdStep = true; 

        this.toastr.error('Failed', 'Error while Updating password !');
        console.log(e);
      });
    } else {
      this.toastr.error('Failed', 'Password and Confirm Password didn\'t matched !');
    }
  }
}

/**
 * Created By : Venkat Salagrama 
 */
