/**
 * Created By : Venkat Salagrama
 */

import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common'

// Components
import { ReportListComponent } from '../report/list/report-list.component';
import { ReportDetailsComponent } from '../report/details/report-details.component';
import { ReportAddComponent } from '../report/add/report-add.component';

// Services
import { routerTransition } from '../../services/config/config.service';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [routerTransition()],
  host: { '[@routerTransition]': '' },
  providers: [DatePipe]
})


export class HomeComponent implements OnInit {

  towersList = [];
  active: string;
  constructor(private router: Router, private apiService: ApiService, private toastr: ToastrService, public datepipe: DatePipe) {
    // Detect route changes for active sidebar menu
    this.router.events.subscribe((val) => {
      this.routeChanged(val);
    });
  }

  ngOnInit() {
    this.getTowers();
  }

  // Detect route changes for active sidebar menu
  routeChanged(val) {
    this.active = val.url;
  }

  getTowers() {
    this.apiService.getTowers().subscribe(res => {  
          
      // console.log(res.value);
			// for(var i=0;i<res.value.length;i++) {
		    // console.log(this.sortAlphaNumeric(res.value, "ASC", "name"));
			var dataSorted = this.apiService.sortAlphaNumeric(res.value, "ASC", "name"); 

			for(var i=0;i<dataSorted.length;i++) {
        var item = { 
            "id" : dataSorted[i].id, 
            "value" : dataSorted[i].name 
          };
         this.towersList.push(item); 
      }       
		}, (e) => {			
      console.log(e);       
		});

    // console.log(this.towersList); 
  }

  // Logout User
  logOut() {
    this.toastr.success('Success', "Logged Out Successfully");
    localStorage.removeItem('userData');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('selectedtowerid');
		localStorage.removeItem('selecteddate');
    this.router.navigate(['/login']);
  }
}


// Define and export child routes of HomeComponent
export const homeChildRoutes: Routes = [
  {
    path: '',
    component: ReportListComponent
  },
  {
    path: 'add',
    component: ReportAddComponent
  },
  {
    path: 'update/:id',
    component: ReportAddComponent
  },
  {
    path: 'detail/:id',
    component: ReportDetailsComponent
  }
];

/**
 * Created By : Venkat Salagrama 
 */
