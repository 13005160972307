import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { enableProdMode } from '@angular/core';

// Modules
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Services
import { AuthService } from './services/auth/auth.service';
import { UserService } from './services/user/user.service';
import { ReportService } from './services/report/report.service';
import { ApiService } from './services/api/api.service';

// Pipes
import { FilterPipe } from './pipes/filter.pipe';
import { PhonePipe } from './pipes/phone.pipe';

// Components
import { AppComponent } from './components/index/app.component';
import { ReportListComponent } from './components/report/list/report-list.component';
import { ReportDetailsComponent } from './components/report/details/report-details.component';
import { ReportAddComponent } from './components/report/add/report-add.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent, homeChildRoutes } from './components/home/home.component';
import { ForgotpwdComponent } from './components/forgotpwd/forgotpwd.component';
import { HighlightStudentDirective } from './directives/highlight-student.directive';
import { AppRoutingModule } from './app-routing.module';

import { WebcamSnapshotModule } from "./webcam-snapshot/webcam-snapshot.module";
import {WebcamModule} from 'ngx-webcam';
import { AppLoadModule } from './modules/webcam/app-load.module';

@NgModule({
	declarations: [
		AppComponent,
		ReportListComponent,
		ReportDetailsComponent,
		ReportAddComponent,
		LoginComponent,
		HomeComponent,
		ForgotpwdComponent,
		FilterPipe,
		PhonePipe,
		HighlightStudentDirective
	],
	imports: [
		BrowserModule,
		RouterModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
        BrowserAnimationsModule,
        NgbModule,
		HttpClientModule,
		WebcamSnapshotModule,
		WebcamModule,
		AppLoadModule,
		ToastrModule.forRoot({
			timeOut: 3000,
			positionClass: 'toast-bottom-right',
			preventDuplicates: true,
		}),
	],
	providers: [AuthService, UserService, ReportService, ApiService],
	bootstrap: [AppComponent]
})

// enableProdMode();

export class AppModule { }
