/**
 * Created By : Venkat Salagrama
 */

import { Component } from '@angular/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})


export class AppComponent {
	title = 'Life guard data operation';

	constructor() {		
	}
}

/**
 * Created By : Venkat Salagrama
 */
