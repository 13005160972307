/**
 * Created By : Venkat Salagrama 
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { first } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiEndpoints } from '../../configs/api-endpoints';

@Injectable()
export class UserService {

	currentUser: any;
  	user: any;

	constructor(private http: HttpClient) { 
		this.currentUser = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    	this.user = this.currentUser.asObservable();
	}

	doLogin(data): Observable<any> {
		return this.http.post<any>(ApiEndpoints.SIGNIN, { username: data.email, password: data.password })
		  .pipe(map(user => {
			// store user details and jwt token in local storage to keep user logged in between page refreshes
			// localStorage.setItem('prospares-jwt-token', JSON.stringify(user));
			this.currentUser.next(user);
			return user;
		  }));
	  }

	  getUserInfo(token): Observable<any> {
		const headers = new HttpHeaders({
            authorization : 'Bearer ' + token
		 });
		return this.http.get<any>(ApiEndpoints.USERINFO, { headers: headers })
		  .pipe(map(user => {			
			return user;
		  }));
	  }

	}

/**
 * Created By : Venkat Salagrama 
 */