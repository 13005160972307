/**
 * Created By : Venkat Salagrama
 */

import { Injectable } from '@angular/core';

@Injectable()
export class ReportService {

  constructor() { }

}
/**
 * Created By : Venkat Salagrama
 */
